import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_campaign_period_shares',
  attributes: [
    'id',
    'period_id',
    'share_ship_id',
    'check_in_token',
    'check_in_at',
    'check_in_ip',
    'created_at',
    'updated_at'
  ],
  editableAttributes: []
}

export default class PromoterCampaignPeriodShare extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
