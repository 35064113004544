import * as types from '../mutation-types'
import PromoterCampaignShareShip from '@models/promoter_campaign_share_ship'

export const register = (
  { dispatch, commit },
  { share_id, campaign_id, model }
) => {
  commit(types.API_REQUEST_START, 'register')

  return new Promise((resolve, reject) => {
    PromoterCampaignShareShip.register({
      share_id,
      campaign_id,
      model
    })
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SHARE_SHIP_SUCCESS, response)

        dispatch(
          'promoterCampaignShareShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignPeriodShares/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignSampleShipments/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: register,
            ref: {
              dispatch,
              commit
            },
            params: { share_id, campaign_id }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const acceptRegistration = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'acceptRegistration')

  return new Promise((resolve, reject) => {
    model
      .acceptRegistration(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SHARE_SHIP_SUCCESS, response)

        dispatch(
          'promoterCampaignShareShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignPeriodShares/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignSampleShipments/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: acceptRegistration,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const acceptInvitation = (
  { dispatch, commit },
  { share_id, campaign_id }
) => {
  commit(types.API_REQUEST_START, 'acceptInvitation')

  return new Promise((resolve, reject) => {
    PromoterCampaignShareShip.acceptInvitation({
      share_id,
      campaign_id
    })
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SHARE_SHIP_SUCCESS, response)

        // store to index entities
        dispatch(
          'promoterCampaignShareShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: acceptInvitation,
            ref: {
              dispatch,
              commit
            },
            params: { share_id, campaign_id }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const reject = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'reject')

  return new Promise((resolve, reject) => {
    model
      .reject(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SHARE_SHIP_SUCCESS, response)

        dispatch(
          'promoterCampaignShareShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignPeriodShares/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignSampleShipments/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: reject,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const submitQuotation = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'submitQuotation')

  return new Promise((resolve, submitQuotation) => {
    model
      .submitQuotation(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SHARE_SHIP_SUCCESS, response)

        dispatch(
          'promoterCampaignShareShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignPeriodShares/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignSampleShipments/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: submitQuotation,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        submitQuotation(errors)
      })
  })
}

export const acceptQuotation = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'acceptQuotation')

  return new Promise((resolve, acceptQuotation) => {
    model
      .acceptQuotation(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SHARE_SHIP_SUCCESS, response)

        dispatch(
          'promoterCampaignShareShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignPeriodShares/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignSampleShipments/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: acceptQuotation,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        acceptQuotation(errors)
      })
  })
}

export const rejectQuotation = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'rejectQuotation')

  return new Promise((resolve, rejectQuotation) => {
    model
      .rejectQuotation(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SHARE_SHIP_SUCCESS, response)

        dispatch(
          'promoterCampaignShareShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignPeriodShares/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignSampleShipments/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: rejectQuotation,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        rejectQuotation(errors)
      })
  })
}
