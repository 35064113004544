import * as types from '../mutation-types'

export const generateCheckInToken = (
  { dispatch, commit },
  { model, promoter_campaign_period_share_id }
) => {
  commit(types.API_REQUEST_START, 'generateCheckInToken')

  return new Promise((resolve, reject) => {
    model
      .generateCheckInToken({ promoter_campaign_period_share_id })
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SHARE_SHIP_SUCCESS, response)

        dispatch(
          'promoterCampaignPeriodShares/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: generateCheckInToken,
            ref: {
              dispatch,
              commit
            },
            params: { model }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const resetCheckInAt = (
  { dispatch, commit },
  { model, promoter_campaign_period_share_id }
) => {
  commit(types.API_REQUEST_START, 'resetCheckInAt')

  return new Promise((resolve, reject) => {
    model
      .resetCheckInAt({ promoter_campaign_period_share_id })
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SHARE_SHIP_SUCCESS, response)

        dispatch(
          'promoterCampaignPeriodShares/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: resetCheckInAt,
            ref: {
              dispatch,
              commit
            },
            params: { model }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
